<style scoped>
.emi-details-table,
.emi-details-table-wrapper {
  width: 100%;
}
.payment-breakdown-table-wrapper {
  width: 100%;
  margin: 15px 0;
}
.payment-breakdown-table-wrapper,
th,
td {
  border-left: 1px solid rgba(144, 144, 144, 0.4);
  border-right: 1px solid rgba(144, 144, 144, 0.4);
  border-bottom: 1px solid rgba(144, 144, 144, 0.4);
  border-collapse: collapse;
}
.emi-details-table-width{
  /* max-width: 920px; */
  /* margin-left: 10px; */
}
/* .payment-breakdown-table-wrapper,
th,
td {
  border: 1px solid rgb(144, 144, 144);
  border-collapse: collapse;
} */
.emi-status-chip {
  width: fit-content;
  text-align: center;
  border-radius: 50px;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 800;
}
.emi-status-chip.overdue {
  background: #fc000019 !important;
  color: #d11010c6 !important;
}
.emi-status-chip.paid {
  background: #58ea0430 !important;
  color: #58ea04 !important;
}
.emi-status-chip.due {
  background: #faf9e0 !important;
  color: #b5ae00 !important;
}
.pointer-cursor {
  cursor: pointer;
}
.bounce-reason {
  display: inline-block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.show-bounce-reason {
  width: auto;
  white-space: normal;
  overflow: auto;
  text-overflow: clip;
}
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
.border-none {
  border: none !important;
  background: #fff !important;
}
.table-header-color {
  background: #f7811b !important;
}
#payment-status-v-chip .v-chip {
  font-size: 12px;
  font-weight: 800;
  background: #def1de !important;
  color: #5cb85c !important;
}
#payment-status-v-chip .v-chip.Partially {
  background: #faf9e0 !important;
  color: #b5ae00 !important;
}
#payment-status-v-chip .v-chip.Partially {
  background: #faf9e0 !important;
  color: #b5ae00 !important;
}
#payment-status-v-chip .v-chip.Overdue {
  background: #fc000019 !important;
  color: #d11010c6 !important;
}
#payment-status-v-chip .v-chip.Due {
  background: #e7f7fb !important;
  color: #31bee8 !important;
}
</style>
<template>
  <div>
    <div v-if="loan_details.loan_id">
      <!-- <div> -->
      <v-row class="px-3 pb-4">
        <v-col cols="3">
          <p class="field-title">Loan ID</p>
          <p class="field-content">
            {{ loan_details.loan_id }}
          </p>
        </v-col>
        <v-col cols="3">
          <p class="field-title">Loan Amount</p>
          <p class="field-content">₹{{ loan_details.loan_amount }}</p>
        </v-col>
        <v-col cols="3">
          <p class="field-title">Tenure</p>
          <p class="field-content">{{ loan_details.number_of_emi }} months</p>
        </v-col>
        <v-col cols="3">
          <p class="field-title">Monthly EMI</p>
          <p class="field-content">₹{{ loan_details.monthly_emi }}</p>
        </v-col>
      </v-row>
      <v-row class="px-3 pb-4">
        <v-col cols="3">
          <p class="field-title">Total payable amount</p>
          <p class="field-content">
            ₹{{ loan_details.total_payable_amount }}
          </p>
        </v-col>
        <!-- <v-col cols="3">
          <p class="field-title">Last Charged On</p>
          <p v-if="loan_details.last_charged_on" class="field-content">
            {{ loan_details.last_charged_on }}
          </p>
          <p v-else class="field-content">
            -
          </p>
        </v-col> -->
        <v-col cols="3">
          <p class="field-title">
            Total amount paid
            <!-- <span class="text-captionv font-weight-light"
              >(Amount + Charges)</span
            > -->
          </p>
          <p class="field-content">
            ₹{{ loan_details.total_already_paid }}
            <!-- <span class="text-caption"
              >( {{ loan_details.monthly_emi }} +
              {{
                loan_details.over_due_charge +
                  loan_details.bounce_charge +
                  loan_details.late_fee
              }}
              )</span
            > -->
          </p>
        </v-col>
        <v-col>
          <div class="field-title d-flex">
            <p class="pr-2">
              Charge breakdown-
            </p>
            <div>
              <p class="field-title">
                Overdue Charge:
                <span class="field-content">
                  ₹{{ loan_details.over_due_charge }}
                </span>
              </p>
              <p class="field-title">
                Bounce Charge:
                <span class="field-content">
                  ₹{{ loan_details.bounce_charge }}
                </span>
              </p>
              <p class="field-title">
                Late Fees:
                <span class="field-content">
                  ₹{{ loan_details.late_fee }}
                </span>
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0 px-3 pb-4"> </v-row>

      <v-row class="px-3 mb-4">
        <v-card flat class="emi-details-table-wrapper">
          <v-card flat outlined>
            <v-data-table
              :headers="headers"
              :items="emi_details"
              hide-default-footer
              :items-per-page="12"
              class="emi-details-table emi-details-table-width"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              show-expand
            >
              <template v-slot:[`item.installment_status`]="{ item }">
                <div class="emi-status-chip">
                  <div id="payment-status-v-chip">
                    <v-chip :class="item.installment_status">
                      {{ item.installment_status }}
                    </v-chip>
                  </div>
                </div>
                <p
                  v-if="
                    (item.installment_status == 'Paid' ||
                      item.installment_status == 'Partially Paid') &&
                      item.payment_breakup[0]
                  "
                  class="text-caption pl-6"
                >
                  {{ item.payment_breakup[0].created_date }}
                </p>
              </template>
              <template v-slot:[`item.overdue_days`]="{ item }">
                <p
                  v-if="item.overdue_days > 0"
                  class="text-center emi-status-chip"
                >
                  {{ item.overdue_days }}
                  <span class="text-caption"> day(s) passed</span>
                </p>
                <div v-else class="center-text emi-status-chip">
                  {{ item.overdue_days * -1 }}
                  <span class="text-caption"> day(s) left</span>
                </div>
              </template>
              <template v-slot:[`item.over_due_charge`]="{ item }">
                <p>₹{{ item.over_due_charge }}</p>
              </template>
              <template v-slot:[`item.bounce_charge`]="{ item }">
                <p>₹{{ item.bounce_charge }}</p>
              </template>
              <template v-slot:[`item.late_fee`]="{ item }">
                <p>₹{{ item.late_fee }}</p>
              </template>
              <template v-slot:[`item.total_payable_amount`]="{ item }">
                <p>₹{{ item.total_payable_amount }}</p>
              </template>
              <template v-slot:[`item.bounce_reason`]="{ item }">
                <div v-if="item.bounce_reason">
                  <v-tooltip top max-width="200" color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="bounce-reason">{{
                        item.bounce_reason
                      }}</span>
                    </template>
                    <span class="show-bounce-reason">{{
                      item.bounce_reason
                    }}</span>
                  </v-tooltip>
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <template>
                    <div v-if="item.payment_breakup[0]">
                      <table class="payment-breakdown-table-wrapper">
                        <thead class="grey-lighten-4">
                          <tr class="table_header_2">
                            <th
                              class="pa-3 "
                              v-for="sub_header in sub_headers"
                              :key="sub_header.text"
                            >
                              {{ sub_header.text }}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(payment_breakup,
                          index) in item.payment_breakup"
                          :key="index"
                          class="text-center"
                        >
                          <tr>
                            <td>{{ payment_breakup.id }}</td>
                            <td>
                              <p>{{ payment_breakup.created_time }}</p>
                              <p>{{ payment_breakup.created_date }}</p>
                            </td>
                            <td>{{ payment_breakup.payment_type }}</td>
                            <td>{{ payment_breakup.payment_mode }}</td>
                            <td>{{ payment_breakup.payment_status }}</td>
                            <td>{{ payment_breakup.amount }}</td>
                            <td>
                              <p v-if="payment_breakup.bounce_reason">
                                {{ payment_breakup.bounce_reason }}
                              </p>
                              <p v-else>-</p>
                            </td>
                            <td>
                              <p v-if="payment_breakup.remark">
                                {{ payment_breakup.remark }}
                              </p>
                              <p v-else>-</p>
                            </td>
                          </tr>
                          <template
                            v-if="payment_breakup.settlement[0]"
                          >
                            <tr class=" table_header_3">
                              <th class="border-none" colspan="5"></th>
                              <th
                                class="pa-3"
                                v-for="header in settlement_headers"
                                :key="header.text"
                              >
                                {{ header.text }}
                              </th>
                            </tr>
                            <template
                              v-for="(settlement,
                              index) in payment_breakup.settlement"
                            >
                              <tr
                                :key="index"
                                v-if="
                                  settlement.installment_number ==
                                    item.installment_number
                                "
                              >
                                <td colspan="5"></td>
                                <td>{{ settlement.amount }}</td>
                                <td>
                                  <p>
                                    {{
                                      new Date(
                                        settlement.created
                                      ).toLocaleTimeString()
                                    }},
                                  </p>
                                  <p>
                                    {{
                                      new Date(
                                        settlement.created
                                      ).toDateString()
                                    }}
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {{
                                      new Date(
                                        settlement.modified
                                      ).toLocaleTimeString()
                                    }},
                                  </p>
                                  <p>
                                    {{
                                      new Date(
                                        settlement.modified
                                      ).toDateString()
                                    }}
                                  </p>
                                </td>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>

                    <div v-else>
                      No payment breakdown available
                    </div>
                  </template>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-row>
    </div>
    <div v-else class="text-center label-text">
      Select a loan from Loan History
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loan_details: [],
      emi_details: [],
      expanded: [],
      singleExpand: false,
      paymentBreakdownData: [
        {
          id: 1,
          date: "25-02-2021",
          amount: 500,
          status: "success",
        },
        {
          id: 2,
          date: "25-02-2021",
          amount: 200,
          status: "initiated",
        },
        {
          id: 3,
          date: "25-02-2021",
          amount: 550,
          status: "failed",
        },
      ],
      headers: [
        {
          text: "EMI no.",
          value: "installment_number",
          sortable: false,
          //   width: 50,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "EMI Date",
          value: "payment_due_date",
          width: 100,
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Status",
          value: "installment_status",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Overdue Days",
          value: "overdue_days",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Overdue Charge",
          value: "over_due_charge",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Bounce Charge",
          value: "bounce_charge",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Late Fees",
          value: "late_fee",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Total Payable",
          value: "total_payable_amount",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Already Paid",
          value: "already_paid",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "Bounce Reason",
          value: "bounce_reason",
          sortable: false,
          class: "table_header black--text font-weight-bold",
        },
        {
          text: "",
          value: "data-table-expand",
          class: "table_header black--text font-weight-bold",
        },
      ],
      sub_headers: [
        {
          text: "ID",
          value: "id",
          sortable: false,
          class: "green font-weight-bold",
        },
        {
          text: "Created On",
          value: "created_date",
          sortable: false,
          class: "green font-weight-bold",
        },
        {
          text: "Payment Type",
          value: "payment_type",
          sortable: false,
          class: "green font-weight-bold",
        },
        {
          text: "Payment Mode",
          value: "payment_mode",
          sortable: false,
          class: "green font-weight-bold",
        },
        {
          text: "Payment Status",
          value: "payment_status",
          sortable: false,
          class: "green font-weight-bold",
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
          class: "green font-weight-bold",
        },
        {
          text: "Bounce Reason",
          value: "bounce_reason",
          sortable: false,
          class: "green font-weight-bold",
        },
        {
          text: "Remark",
          value: "remark",
          sortable: false,
          class: "green font-weight-bold",
        },
      ],
      settlement_headers: [
        // {
        //   text: "EMI no.",
        //   value: "installment_number",
        // },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Created",
          value: "created",
        },
        {
          text: "Modified",
          value: "modified",
        },
      ],
    };
  },
  mounted() {
    if (this.store_loan_id) {
      this.getEmiDetails();
    }
  },
  computed: {
    ...mapGetters({
      store_loan_id: "new_customer/getLoanId",
    }),
  },
  watch: {
    store_loan_id(value) {
      this.getEmiDetails();
    },
  },
  methods: {
    getEmiDetails() {
      const self = this;

      let loan_id = this.store_loan_id ? this.store_loan_id : "";
      let params = {
        customer_id: this.decrypt(this.$route.params.id),
        loan_id: loan_id,
      };
      // console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("emi-details",response);
          self.loan_details = response.data.installment_data;
          self.emi_details = response.data.installment_data.installment_list;
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.EMI_DETAILS,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getPaymentBreaskdown(value) {
      console.log("expand", value);
    },
  },
};
</script>
